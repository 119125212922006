<template>
  <TransitionRoot as="template" :show="isOpen">
    <Dialog
      as="div"
      static
      class="fixed z-10 inset-0 overflow-y-auto"
      @close="setIsOpen(false)"
      :open="isOpen"
    >
      <div
        class="
          flex
          items-end
          justify-center
          min-h-screen
          pt-4
          px-4
          pb-20
          text-center
          sm:block
          sm:p-0
        "
      >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 translate-y-0 sm:scale-100"
          leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            class="
              inline-block
              align-bottom
              bg-white
              rounded-lg
              p-8
              text-left
              overflow-hidden
              shadow-xl
              transform
              transition-all
              sm:my-8
              sm:align-middle
              sm:max-w-2xl
              sm:w-fal
              sm:p-8
            "
          >
            <form
              class="space-y-6"
              action="#"
              method="POST"
              @submit.prevent="sendMail"
            >
              <div>
                <label
                  for="to_name"
                  class="block text-sm font-medium text-gray-700"
                >
                  Nombre del destinatario
                </label>
                <div class="mt-1">
                  <input
                    id="to_name"
                    name="to_name"
                    type="text"
                    autocomplete="current-password"
                    required="required"
                    class="
                      appearance-none
                      block
                      w-full
                      px-3
                      py-2
                      border border-gray-300
                      rounded-md
                      shadow-sm
                      placeholder-gray-400
                      focus:outline-none
                      focus:ring-yellow-500
                      focus:border-yellow-500
                      sm:text-sm
                    "
                  />
                </div>
              </div>

              <div>
                <label
                  for="to_email"
                  class="block text-sm font-medium text-gray-700"
                >
                  Email del destinatario
                </label>
                <div class="mt-1">
                  <input
                    id="to_email"
                    name="to_email"
                    type="email"
                    autocomplete="email"
                    required="required"
                    class="
                      appearance-none
                      block
                      w-full
                      px-3
                      py-2
                      border border-gray-300
                      rounded-md
                      shadow-sm
                      placeholder-gray-400
                      focus:outline-none
                      focus:ring-yellow-500
                      focus:border-yellow-500
                      sm:text-sm
                    "
                  />
                </div>
              </div>

              <input
                type="hidden"
                id="catalog_title"
                name="catalog_title"
                :value="pdfName"
              />
              <input
                type="hidden"
                id="catalog_url"
                name="catalog_url"
                :value="pdfFile"
              />

              <div>
                <button
                  type="submit"
                  class="
                    w-full
                    flex
                    justify-center
                    py-2
                    px-4
                    border border-transparent
                    rounded-md
                    shadow-sm
                    text-sm
                    font-medium
                    text-white
                    bg-yellow-500
                    hover:bg-yellow-600
                    focus:outline-none
                    focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500
                  "
                >
                  Enviar
                </button>
              </div>
            </form>

            <div class="rounded-md bg-red-50 p-4 mt-8" v-if="emailSendError">
              <div class="flex">
                <div class="flex-shrink-0">
                  <XCircleIcon
                    class="h-5 w-5 text-red-400"
                    aria-hidden="true"
                  />
                </div>
                <div class="ml-3">
                  <h3 class="text-sm font-medium text-red-800">
                    No se puede enviar el email, intentelo mas tarde.
                  </h3>
                </div>
              </div>
            </div>

            <div class="rounded-md bg-green-50 p-4 mt-8" v-if="emailSent">
              <div class="flex">
                <div class="flex-shrink-0">
                  <CheckCircleIcon
                    class="h-5 w-5 text-green-400"
                    aria-hidden="true"
                  />
                </div>
                <div class="ml-3">
                  <p class="text-sm font-medium text-green-800">
                    El email se ha enviado correctamente
                  </p>
                </div>
              </div>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>

  <div>
    <h1 class="font-zeyada text-4xl my-4 text-center">
      {{ $filters.capitalize(catalog.titulo) }}
    </h1>

    <iframe
      frameborder="0"
      class="w-full"
      height="500"
      :src="pdfUrl"
      marginwidth="0"
      marginheight="0"
    ></iframe>

    <div class="mt-3 text-right">
      <button
        type="button"
        class="
          inline-flex
          items-center
          px-3
          py-2
          mr-2
          border border-transparent
          shadow-sm
          text-sm
          leading-4
          font-medium
          rounded-md
          text-white
          bg-yellow-500
          hover:bg-yellow-600
          focus:outline-none
          focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500
        "
        @click="sendMail"
      >
        <MailIcon class="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
        Enviar por email
      </button>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import {
  Dialog,
  DialogOverlay,
  TransitionRoot,
  TransitionChild,
} from "@headlessui/vue";
import router from "@/router";
import { useRoute } from "vue-router";
import {
  API_BASE_URL,
  EMAILJS_SERVICE_ID,
  EMAILJS_TEMPLATE_ID,
  EMAILJS_USER_ID,
} from "@/config/parameters";
import { MailIcon, XCircleIcon, CheckCircleIcon } from "@heroicons/vue/outline";
import emailjs from "emailjs-com";

export default {
  name: "Catalogo",
  components: {
    MailIcon,
    XCircleIcon,
    CheckCircleIcon,
    Dialog,
    DialogOverlay,
    TransitionRoot,
    TransitionChild,
  },
  setup() {
    const route = useRoute();
    const catalog = ref({});
    const pdfAPIReady = ref(false);
    const pdfFile = ref("");
    const pdfName = ref("");
    const pdfUrl = ref("about:blank");
    const isOpen = ref(false);
    const emailSendError = ref(false);
    const emailSent = ref(false);

    onMounted(() => {
      fetch(`${API_BASE_URL}/catalogos?slug=${route.params.slug}`)
        .then((response) => response.json())
        .then((data) => {
          if (data.length === 0) {
            router.push({ name: "NotFound", params: { catchAll: "404" } });
          }
          catalog.value = Object.assign({}, data[0]);
          pdfFile.value = API_BASE_URL + catalog.value?.fichero?.url;
          pdfName.value = catalog.value?.titulo;
          pdfUrl.value = catalog.value?.url;
        })
        .catch((error) => console.error(error));
    });

    if (window.AdobeDC) pdfAPIReady.value = true;

    const sendMail = (e) => {
      isOpen.value = true;

      emailjs
        .sendForm(
          EMAILJS_SERVICE_ID,
          EMAILJS_TEMPLATE_ID,
          e.target,
          EMAILJS_USER_ID
        )
        .then(
          (result) => {
            console.log("SUCCESS!", result.status, result.text);
            emailSent.value = true;

            setTimeout(() => (isOpen.value = false), 3000);
          },
          (error) => {
            console.error("FAILED...", error);
            emailSendError.value = true;
          }
        );
    };

    return {
      catalog,
      pdfAPIReady,
      pdfFile,
      pdfName,
      pdfUrl,
      sendMail,
      isOpen,
      setIsOpen(value) {
        isOpen.value = value;
      },
      emailSendError,
      emailSent,
    };
  },
};
</script>